<template>
  <div>
    <h4>DIAGNOSTICO Y PLAN DE TRATAMIENTO</h4>

    <div class="btn-block btn bg-info text-light">
      <strong>DATOS DEL PACIENTE</strong>
    </div>

    <div class="mt-2 mb-2">
      Nombre del Paciente: &nbsp;
      <strong>{{ client.name }}</strong>
    </div>

    <div class="btn-block btn bg-info text-light">
      <strong>PROTOCOLO DE INICIO</strong>
    </div>

    <div class="row" v-if="config">
      <!-- <table class="table table-sm">
        <tr v-for="(l,index) in details" :key="index">
          <td >
            <span>{{l.detail_name}} </span>
            <div v-show="l.maked">
            <small>{{l.user_name}} </small>
            </div>
          </td>
          <td>
            <app-checkbox :disabled="true" placeholder="" v-model="l.maked"></app-checkbox>
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" v-model="reg[l.product_name]" placeholder="Obs" />
          </td>
        </tr>
      </table> -->
      <div class="col">
        <PreTests :disabled="true" :details="details"></PreTests>
      </div>
      <div class="col">
        <label for="">Observaciones</label>
        <app-textarea :rows="6" v-model="reg.pre_test"></app-textarea>
      </div>
    </div>

    <div class="btn-block btn bg-success text-light">DIAGNOSTICO</div>

    <div class="form-group">
      <label for>Diagnostico General</label>
      <app-textarea v-model="reg.diagnosis"></app-textarea>
    </div>
    <div class="form-group">
      <label for>Análisis Facial</label>
      <app-textarea v-model="reg.facial_analysis"></app-textarea>
    </div>
    <div class="form-group">
      <label for>Análisis Dentario</label>
      <app-textarea v-model="reg.dental_analysis"></app-textarea>
    </div>

    <div class="btn-block btn bg-success text-light">OBJETIVOS</div>

    <div class="form-group">
      <label for>Objetivo de Tratamiento</label>
      <app-textarea v-model="reg.target"></app-textarea>
    </div>

    <div class="btn-block btn bg-success text-light">PLAN DE TRATAMIENTO</div>

    <table class="table table-bordered table-sm mb-2">
      <tr>
        <th></th>
        <th class="text-center">Descripcion</th>
        <th>Meses</th>
      </tr>
      <tr v-for="l in plan" :key="l">
        <td>{{ l }}</td>
        <td>
          <input type="text" class="editable" v-model="reg['plan_' + l]" />
        </td>
        <td style="width: 120px">
          <input type="number" class="editable" v-model="reg['mes_' + l]" />
        </td>
      </tr>
    </table>

    <div class="form-group">
      <label class="text-danger" for>DURACION DEL TRATAMIENTO*</label>
      <input type="number" class="form-control" v-model="reg.duration" />
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for>FECHA DE INICIO</label>
          <app-datepicker
            v-model="reg.ini_date"
            @input="reg.end_date = getEndDateFromIni($event)"
          ></app-datepicker>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for>FECHA DE CULMINACION</label>
          <app-datepicker v-model="reg.end_date"></app-datepicker>
        </div>
      </div>
    </div>
    <div class="btn-block btn bg-info text-light">
      TIPO DE APARATOLOGÍA / OBSERVACIONES
    </div>

    <div class="form-group mt-2">
      <app-textarea v-model="reg.orthopedics"></app-textarea>
    </div>

    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import { mapState } from "vuex";
import PreTests from "./PreTests";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    PreTests
  },
  props: {
    // client_id: {},
    treatment_plan_id: {}
  },
  watch: {
    client_id() {
      this.getPlan();
    }
  },
  computed: {
    ...mapState({
      client: s => s.dental.client,
      config: s => s.config.dental.orthodontic
    })
  },
  data: () => ({
    reg: {
      pre_test: "",
      diagnosis: "",
      facial_analysis: "",
      dental_analysis: "",
      target: "",
      orthopedics: "",
      duration: null
    },
    products: {},
    plan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    details: [],
    list: {}
  }),
  mounted() {
    this.getPlan();
  },
  methods: {
    getEndDateFromIni(date) {
      var inDate = myMoment(date);
      return inDate.add(this.reg.duration, "months").format("YYYY/MM/DD");
    },
    save() {
      // console.log(this.reg);
      DentalClinicService.saveOrthodonticPlan({
        treatment_plan_id: this.treatment_plan_id,
        ...this.reg
      });
    },
    getPlan() {
      DentalClinicService.getTreatmentPlanDetails({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.details = res;
      });
      DentalClinicService.getOrthodonticPlan({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        if (res) {
          this.reg = res.adds;
        }
      });
    }
  }
};
</script>

<style>
</style>
